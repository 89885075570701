<template>
  <div>
    <div v-if="collapsed && collapsible">
      <v-text-field
        :value="
          address.addressLine1 +
          ' ' +
          address.city +
          ' ' +
          address.provinceState +
          ' ' +
          address.postCode +
          ' ' +
          address.country
        "
        label="Address"
        outlined
        readonly
        hide-details="auto"
        :disabled="disabled"
        @click="collapsed = false"
      />
    </div>
    <div v-else>
      <v-row>
        <v-col>
          <v-text-field
            v-model="address.addressLine1"
            label="Address Line 1"
            outlined
            hide-details="auto"
            :disabled="disabled"
            :error-messages="getFormErrors($v, 'address.addressLine1')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="address.addressLine2"
            label="Address Line 2"
            outlined
            hide-details="auto"
            :disabled="disabled"
            :error-messages="getFormErrors($v, 'address.addressLine2')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <custom-select
            v-if="!otherCountry"
            v-model="address.country"
            :items="countryOptions"
            label="Country"
            :disabled="disabled"
            :error-messages="getFormErrors($v, 'address.country')"
            @input="countryChanged"
          />
          <v-text-field
            v-else
            ref="countryTextField"
            v-model="address.country"
            label="Country (2-letter code)"
            outlined
            hide-details="auto"
            :disabled="disabled"
            :error-messages="getFormErrors($v, 'address.country')"
          />
        </v-col>
        <v-col cols="12" md="6">
          <custom-select
            v-if="address.country && address.country !== 'OT'"
            v-model="address.provinceState"
            :items="provinceStateOptions[address.country]"
            :label="provinceStateLabel"
            :disabled="disabled"
            :error-messages="getFormErrors($v, 'address.provinceState')"
          />
          <v-text-field
            v-else
            v-model="address.provinceState"
            label="Province / State"
            outlined
            hide-details="auto"
            :disabled="disabled"
            :error-messages="getFormErrors($v, 'address.provinceState')"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="address.city"
            label="City"
            outlined
            hide-details="auto"
            :disabled="disabled"
            :error-messages="getFormErrors($v, 'address.city')"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="address.postCode"
            label="Postal Code (no spaces)"
            outlined
            hide-details="auto"
            :disabled="disabled"
            :error-messages="getFormErrors($v, 'address.postCode')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="address.latitude"
            label="Latitude"
            outlined
            hide-details="auto"
            :disabled="disabled"
            :error-messages="getFormErrors($v, 'address.latitude')"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="address.longitude"
            label="Longitude"
            outlined
            hide-details="auto"
            :disabled="disabled"
            :error-messages="getFormErrors($v, 'address.longitude')"
          />
        </v-col>
      </v-row>
      <v-btn
        v-if="collapsible"
        class="mb-2 mt-4"
        color="lighttext"
        text
        x-small
        block
        :disabled="disabled"
        @click="collapsed = true"
      >
        <v-icon small class="mr-2">mdi-chevron-up</v-icon>
        Collapse Address
        <v-icon small class="ml-2">mdi-chevron-up</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength, numeric } from "vuelidate/lib/validators"
import CustomSelect from "../../shared/CustomSelect.vue"

export default {
  name: "address-form",
  components: {
    CustomSelect,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    collapsible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      address: {},
      otherCountry: false,
      collapsed: !this.addressIsBlank,
    }
  },
  computed: {
    countryOptions() {
      return [
        { text: "Canada", value: "CA" },
        { text: "United States", value: "US" },
        { text: "Mexico", value: "MX" },
        { text: "United Kingdom", value: "GB" },
        { text: "Other", value: "OT" },
      ]
    },
    provinceStateOptions() {
      return {
        CA: [
          { text: "Alberta", value: "AB" },
          { text: "British Columbia", value: "BC" },
          { text: "Manitoba", value: "MB" },
          { text: "New Brunswick", value: "NB" },
          { text: "Newfoundland and Labrador", value: "NL" },
          { text: "Northwest Territories", value: "NT" },
          { text: "Nova Scotia", value: "NS" },
          { text: "Nunavut", value: "NU" },
          { text: "Ontario", value: "ON" },
          { text: "Prince Edward Island", value: "PE" },
          { text: "Quebec", value: "QC" },
          { text: "Saskatchewan", value: "SK" },
          { text: "Yukon", value: "YT" },
        ],
        US: [
          { text: "Alabama", value: "AL" },
          { text: "Alaska", value: "AK" },
          { text: "Arizona", value: "AZ" },
          { text: "Arkansas", value: "AR" },
          { text: "California", value: "CA" },
          { text: "Colorado", value: "CO" },
          { text: "Connecticut", value: "CT" },
          { text: "Delaware", value: "DE" },
          { text: "District of Columbia", value: "DC" },
          { text: "Florida", value: "FL" },
          { text: "Georgia", value: "GA" },
          { text: "Hawaii", value: "HI" },
          { text: "Idaho", value: "ID" },
          { text: "Illinois", value: "IL" },
          { text: "Indiana", value: "IN" },
          { text: "Iowa", value: "IA" },
          { text: "Kansas", value: "KS" },
          { text: "Kentucky", value: "KY" },
          { text: "Louisiana", value: "LA" },
          { text: "Maine", value: "ME" },
          { text: "Maryland", value: "MD" },
          { text: "Massachusetts", value: "MA" },
          { text: "Michigan", value: "MI" },
          { text: "Minnesota", value: "MN" },
          { text: "Mississippi", value: "MS" },
          { text: "Missouri", value: "MO" },
          { text: "Montana", value: "MT" },
          { text: "Nebraska", value: "NE" },
          { text: "Nevada", value: "NV" },
          { text: "New Hampshire", value: "NH" },
          { text: "New Jersey", value: "NJ" },
          { text: "New Mexico", value: "NM" },
          { text: "New York", value: "NY" },
          { text: "North Carolina", value: "NC" },
          { text: "North Dakota", value: "ND" },
          { text: "Ohio", value: "OH" },
          { text: "Oklahoma", value: "OK" },
          { text: "Oregon", value: "OR" },
          { text: "Pennsylvania", value: "PA" },
          { text: "Rhode Island", value: "RI" },
          { text: "South Carolina", value: "SC" },
          { text: "South Dakota", value: "SD" },
          { text: "Tennessee", value: "TN" },
          { text: "Texas", value: "TX" },
          { text: "Utah", value: "UT" },
          { text: "Vermont", value: "VT" },
          { text: "Virginia", value: "VA" },
          { text: "Washington", value: "WA" },
          { text: "West Virginia", value: "WV" },
          { text: "Wisconsin", value: "WI" },
          { text: "Wyoming", value: "WY" },
        ],
        MX: [
          { text: "Aguascalientes", value: "AG" },
          { text: "Baja California", value: "BC" },
          { text: "Baja California Sur", value: "BS" },
          { text: "Campeche", value: "CM" },
          { text: "Chiapas", value: "CS" },
          { text: "Chihuahua", value: "CH" },
          { text: "Coahuila", value: "CO" },
          { text: "Colima", value: "CL" },
          { text: "Durango", value: "DG" },
          { text: "Guanajuato", value: "GT" },
          { text: "Guerrero", value: "GR" },
          { text: "Hidalgo", value: "HG" },
          { text: "Jalisco", value: "JA" },
          { text: "México", value: "ME" },
          { text: "Michoacán", value: "MI" },
          { text: "Morelos", value: "MO" },
          { text: "Nayarit", value: "NA" },
          { text: "Nuevo León", value: "NL" },
          { text: "Oaxaca", value: "OA" },
          { text: "Puebla", value: "PU" },
          { text: "Querétaro", value: "QE" },
          { text: "Quintana Roo", value: "QR" },
          { text: "San Luis Potosí", value: "SL" },
          { text: "Sinaloa", value: "SI" },
          { text: "Sonora", value: "SO" },
          { text: "Tabasco", value: "TB" },
          { text: "Tamaulipas", value: "TM" },
          { text: "Tlaxcala", value: "TL" },
          { text: "Veracruz", value: "VE" },
          { text: "Yucatán", value: "YU" },
          { text: "Zacatecas", value: "ZA" },
        ],
        GB: [
          { text: "England", value: "EN" },
          { text: "Northern Ireland", value: "NI" },
          { text: "Scotland", value: "SC" },
          { text: "Wales", value: "WA" },
        ],
        OT: [],
      }
    },
    provinceStateLabel() {
      switch (this.address.country) {
        case "CA":
          return "Province"
        case "US":
        case "MX":
          return "State"
        case "GB":
          return "Legal Jurisdiction"
        default:
          return "Province / State"
      }
    },
    addressIsBlank() {
      return (
        !this.address.addressLine1 &&
        !this.address.addressLine2 &&
        !this.address.city &&
        !this.address.provinceState &&
        !this.address.postCode &&
        !this.address.country &&
        !this.address.latitude &&
        !this.address.longitude
      )
    },
  },
  validations: {
    address: {
      addressLine1: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(100),
      },
      addressLine2: {
        maxLength: maxLength(100),
      },
      city: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
      },
      provinceState: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(2),
      },
      postCode: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(10),
      },
      country: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(2),
      },
      latitude: {
        numeric,
      },
      longitude: {
        numeric,
      },
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.address = val
      },
      deep: true,
    },
    address: {
      handler: function (val) {
        this.$emit("input", val)
      },
      deep: true,
    },
  },
  mounted() {
    this.address = JSON.parse(JSON.stringify(this.value))
  },
  methods: {
    countryChanged() {
      if (this.address.country === "OT") {
        this.address.country = ""
        this.otherCountry = true
        this.$nextTick(() => {
          this.$refs.countryTextField.focus()
        })
      }
    },
    validate() {
      this.$v.$touch()
      return !this.$v.$invalid
    },
  },
}
</script>
