<template>
  <v-text-field
    v-model="internalValue"
    type="text"
    outlined
    :dense="dense"
    :label="label"
  >
    <template slot="append">
      <v-menu
        ref="colorPickerMenu"
        offset-y
        style="z-index: 200"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            x-small
            elevation="0"
            v-bind="attrs"
            :color="internalValue"
            style="border: solid 1px #ddd"
            v-on="on"
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </v-btn>
        </template>
        <v-card class="align-center">
          <v-color-picker v-model="internalValue" hide-details class="mt-n4" />
          <v-card-actions>
            <v-btn color="primary" @click="saveColor">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "#000000",
    },
    label: {
      type: String,
      default: "Color",
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: this.value,
    }
  },
  watch: {
    internalValue(newValue) {
      this.$emit("input", newValue)
    },
    value(newValue) {
      this.internalValue = newValue
    },
  },
  methods: {
    saveColor() {
      this.$refs.colorPickerMenu.save(this.internalValue)
    },
  },
}
</script>
