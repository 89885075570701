import { mapState } from "vuex"

export default {
  data() {
    return {
      needsRefresh: false,
      needsRefreshNew: false,
      restaurantToSync: null,
    }
  },
  computed: {
    ...mapState(["currentUserRole", "firebaseRefs", "channels"]),
  },
  methods: {
    alreadyLinkedToSquare(restaurantId) {
      if (this.channels.length === 0) {
        return false
      }

      try {
        const channel = this.channels.find(
          channel =>
            channel.restaurantData &&
            channel.restaurantData[0].restaurantId === restaurantId
        )
        return channel !== undefined && "accessToken" in channel
      } catch (error) {
        console.error(error)
      }
    },
    linkSquare(restaurant) {
      this.restaurantToSync = restaurant
      const url = this.getSquareOAuthUrl(restaurant.id)
      this.openSquareOAuthWindow(url, false)
    },
    addLinkSquare() {
      const url = this.getSquareOAuthUrl("NEWENTRY")
      this.openSquareOAuthWindow(url, true)
    },
    getSquareOAuthUrl(restaurantId) {
      const organizationId = this.firebaseRefs.organization.id
      const randomVal = Math.random().toString(36).slice(2)
      const baseUrl = "https://connect.squareup.com/oauth2/authorize"
      const clientId = "sq0idp-xOwTGREqCx55nb60JXJ29g"
      const scope = [
        "CUSTOMERS_WRITE",
        "CUSTOMERS_READ",
        "PAYMENTS_READ",
        "PAYMENTS_WRITE",
        "ITEMS_READ",
        "ITEMS_WRITE",
        "INVENTORY_READ",
        "INVENTORY_WRITE",
        "ORDERS_READ",
        "ORDERS_WRITE",
        "MERCHANT_PROFILE_READ",
        "MERCHANT_PROFILE_WRITE",
        "LOYALTY_READ",
        "LOYALTY_WRITE",
        "PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS",
      ].join("+")

      return `${baseUrl}?client_id=${clientId}&scope=${scope}&session=false&state=${organizationId}|${restaurantId}|${randomVal}`
    },
    openSquareOAuthWindow(url, isNew) {
      window.open(url, "_blank")
      setTimeout(() => {
        this.needsRefresh = true
        this.needsRefreshNew = !!isNew
      }, 500)
    },
  },
}
