<template>
  <div>
    <h3>Restaurant Information</h3>
    <v-row justify="start" class="mt-3">
      <v-col cols="4">
        <v-text-field
          v-model="name"
          label="Restaurant Name"
          outlined
          hide-details="auto"
          :disabled="!fieldsEnabled"
          :error-messages="getFormErrors($v, 'name')"
        />
      </v-col>
      <v-col cols="4">
        <custom-select
          v-model="selectedCuisines"
          :items="cuisineOptions"
          label="Cuisines"
          multiple
          size="lg"
          no-select-all
          autocomplete
          :disabled="!fieldsEnabled"
          :error-messages="getFormErrors($v, 'cuisine')"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="prepTime"
          label="Prep Time"
          suffix="min"
          outlined
          number
          hide-details="auto"
          :error-messages="getFormErrors($v, 'prepTime')"
          :disabled="!fieldsEnabled"
        />
      </v-col>
      <v-col cols="12">
        <address-form v-model="address" :disabled="!fieldsEnabled" />
      </v-col>
    </v-row>
    <v-row v-if="!hideImages">
      <v-col cols="12" md="4">
        <h3 class="my-5 mb-10 text-center">Logo</h3>
        <custom-image-upload
          style="max-width: 250px"
          class="mx-auto"
          name="logo"
          :imageUrl="images.logo"
          :allowEdit="fieldsEnabled"
          @imageProcessed="images.logo = $event"
        />
      </v-col>
      <v-col cols="12" md="8">
        <h3 class="my-5 mb-10 text-center">Hero Image</h3>
        <custom-image-upload
          name="hero"
          :imageUrl="images.hero"
          :allowEdit="fieldsEnabled"
          @imageProcessed="images.hero = $event"
        />
      </v-col>
    </v-row>
    <h3 class="my-5">Social Media</h3>
    <v-row justify="start" class="mt-3">
      <v-col cols="4">
        <v-select
          v-model="social.type"
          :items="socialTypes"
          item-value="id"
          item-text="name"
          label="Select Platform"
          outlined
          :error-messages="getFormErrors($v, 'social.type')"
          :disabled="!fieldsEnabled"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="social.handle"
          label="Handle"
          outlined
          :error-messages="getFormErrors($v, 'social.handle')"
          :disabled="!fieldsEnabled"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="social.url"
          label="URL"
          outlined
          :error-messages="getFormErrors($v, 'social.url')"
          :disabled="!fieldsEnabled"
        />
      </v-col>
    </v-row>
    <h3 class="mb-4">Hours</h3>
    <weekly-hours
      v-model="hours"
      :error-messages="getFormErrors($v, 'hours')"
      :disabled="!fieldsEnabled"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import CustomSelect from "../../shared/CustomSelect.vue"
import AddressForm from "../../shared/forms/AddressForm.vue"
import CustomImageUpload from "../../shared/CustomImageUpload.vue"
import WeeklyHours from "../../shared/forms/datetime/WeeklyHours.vue"
import { required, numeric } from "vuelidate/lib/validators"

export default {
  name: "add-edit-form",
  components: {
    CustomSelect,
    AddressForm,
    CustomImageUpload,
    WeeklyHours,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideImages: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: "",
      selectedCuisines: [],
      prepTime: "",
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        country: "",
        latitude: "",
        longitude: "",
        postCode: "",
        provinceState: "",
      },
      hours: {},
      enabledFeatures: {
        delivery: false,
        multiLocation: false,
      },
      images: {
        logo: "",
        hero: "",
      },
      social: {
        type: "",
        handle: "",
        url: "",
      },
      isDeleted: false,
      isEnabled: true,
      closeDisabled: false,
      awaitingResponse: false,
      socialTypes: [
        { name: "Facebook", id: "facebook" },
        { name: "Twitter", id: "twitter" },
        { name: "Instagram", id: "instagram" },
      ],
      mainErrorMessage: "",
    }
  },
  validations() {
    return {
      name: { required },
      storeNumber: {},
      prepTime: { numeric },
      address: {},
      hours: {},
      enabledFeatures: {},
      social: {},
    }
  },
  computed: {
    ...mapState(["restaurants", "cuisines"]),
    ...mapGetters(["getRestaurantSyncStatus"]),
    editMode() {
      return this.itemToEdit != null
    },
    itemToEdit() {
      return this.restaurants.find(({ id }) => id === this.id)
    },
    fieldsEnabled() {
      if (this.disabled) {
        return false
      }
      if (!this.editMode) {
        return true
      }
      return !this.getRestaurantSyncStatus(this.itemToEdit.id)
    },
    cuisineOptions() {
      const categoriesMap = this.cuisines.reduce(
        (acc, { id, ordinal, categories }) => {
          categories.forEach(category => {
            acc[category] = acc[category] || []
            acc[category].push({ id, ordinal })
          })
          return acc
        },
        {}
      )
      return Object.entries(categoriesMap)
        .sort((a, b) => a > b)
        .reduce((acc, [header, items]) => {
          acc.push({ header })
          acc.push(...items.sort((a, b) => a.id > b.id).map(item => item.id))
          return acc
        }, [])
    },
  },
  watch: {
    itemToEdit: {
      handler(value) {
        if (value) {
          this.prepEdit()
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["updateRestaurants", "addRestaurant"]),
    validateFields() {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    async submitHandle() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.closeDisabled = this.awaitingResponse = true
        const fields = {
          id: "string",
          name: "string",
          storeNumber: "string",
          cuisines: "array",
          prepTime: "number",
          address: "object",
          hours: "object",
          enabledFeatures: "object",
          social: "object",
          images: "object",
          isEnabled: "boolean",
          isDeleted: "boolean",
        }
        var payload = {}
        for (const [field, type] of Object.entries(fields)) {
          switch (type) {
            case "number":
              payload[field] = parseFloat(this[field]) || 0
              break
            case "boolean":
              payload[field] = !!this[field] || false
              break
            case "object":
              payload[field] = this[field] || {}
              break
            case "array":
              if (field === "cuisines") {
                payload[field] = this.selectedCuisines || []
              } else {
                payload[field] = this[field]?.length > 0 ? this[field] : []
              }
              break
            default:
              if (this[field] !== null && this[field] !== undefined) {
                payload[field] = this[field]
              } else {
                payload[field] = ""
              }
              break
          }
        }
        if (this.editMode) {
          await this.updateRestaurants(payload)
            .then(() => {
              this.$emit("update", { id: this.id })
              this.closeForm()
            })
            .catch(err => {
              this.mainErrorMessage = err
            })
        } else if (!this.editMode) {
          await this.addRestaurant(payload)
            .then(id => {
              this.$emit("update", { id })
              this.closeForm()
            })
            .catch(err => {
              this.mainErrorMessage = err
            })
        }
      }
    },
    closeForm() {
      this.$router.push({ params: { form: null, id: null } })
    },
    getAddressData(addy) {
      this.address.addressLine1 = addy.street_number + " " + addy.route
      this.address.city = addy.locality
      this.address.country = addy.country
      this.address.postCode = addy.postal_code
      this.address.provinceState = addy.administrative_area_level_1
      this.address.latitude = addy.latitude
      this.address.longitude = addy.longitude
    },
    prepEdit() {
      this.name = this.itemToEdit.name
      this.storeNumber = this.itemToEdit.storeNumber
      this.selectedCuisines = this.itemToEdit.cuisines
      this.prepTime = this.itemToEdit.prepTime
      this.address = this.itemToEdit.address
      this.isEnabled = this.itemToEdit.isEnabled
      this.enabledFeatures = this.itemToEdit.enabledFeatures
      this.social = this.itemToEdit.social
        ? this.itemToEdit.social
        : {
            type: "",
            handle: "",
            url: "",
          }
      this.images = this.itemToEdit.images
        ? this.itemToEdit.images
        : {
            logo: "",
            hero: "",
          }
      this.hours = this.itemToEdit.hours
        ? this.itemToEdit.hours
        : this.getEmptyWeekRange(true)
    },
  },
}
</script>
