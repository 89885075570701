import { createUserWithEmailAndPassword } from "firebase/auth"
import { adminAuth } from "@/firebaseConfig"
import { Timestamp } from "firebase/firestore"
import topLevelMixins from "@/AuthenticatedContent/mixins.js"
import { mapState } from "vuex"

export default {
  mixins: [topLevelMixins],
  computed: {
    ...mapState(["allUsers"]),
  },
  methods: {
    createUser(newUser) {
      return new Promise((resolve, reject) => {
        // We need to use the admin auth to create the user so it doesn't affect the currently logged in user
        createUserWithEmailAndPassword(adminAuth, newUser.email, newUser.password)
          .then(async userCredential => {
            const user = userCredential.user

            // We no longer need this secondary auth, so we log out of that (the main auth is still logged in)
            await adminAuth.signOut()
            await this.updateUsers(user.uid, true, newUser)
            resolve()
          })
          .catch(async error => {
            // if user already exists, add this user using their existing uid
            if (error.code === "auth/email-already-in-use") {
              console.warn(
                "User already exists, adding user with existing uid",
                newUser.email,
                error
              )
              try {
                const uid = await this.getUidByEmail(newUser.email)
                await this.updateUsers(uid, true, newUser)
                resolve()
              } catch (error) {
                console.error("Error getting uid by email", error)
                reject(new Error("There was an error adding existing user."))
              }
            } else {
              reject(new Error(error.message))
            }
          })
      })
    },
    async updateUsers(uid, isNewUser = false, newUser) {
      const updatedUsers = { ...this.allUsers }

      updatedUsers[uid] = {
        name: newUser.name,
        email: newUser.email,
        restaurantIds: newUser.restaurantIds,
        role: newUser.role,
        userId: uid,
      }
      if (newUser.phoneNumber) {
        updatedUsers[uid].phoneNumber = newUser.phoneNumber
      }
      if (isNewUser) {
        updatedUsers[uid].createdAt = Timestamp.fromDate(new Date())
      }

      await this.$store.dispatch("updateUsers", updatedUsers)
    },
  },
}
