<template>
  <v-dialog v-model="needsRefresh" persistent retain-focus width="500">
    <v-card>
      <v-card-title>Done</v-card-title>
      <v-card-text class="pb-6">
        <p v-if="value">
          Your restaurant has been successfully connected to Square. Please press the
          button below to import from your Square account now.
        </p>
        <p v-else-if="needsRefreshNew">
          Your restaurant has been successfully created and connected to Square.
          Please press the button below to import from your Square account now.
        </p>
        <p v-else>Press the button below to continue.</p>
        <v-btn
          color="primary"
          :loading="awaitingResponse"
          @click="refreshData(skipConfirmation)"
        >
          Continue
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex"

export default {
  name: "sync-dialog",
  props: {
    needsRefresh: {
      type: Boolean,
      default: false,
    },
    needsRefreshNew: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: null,
    },
    skipConfirmation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      awaitingResponse: false,
    }
  },
  computed: {
    ...mapState(["channels", "restaurants", "firebaseRefs"]),
  },
  methods: {
    ...mapActions(["fetchAllSquareRestaurantData"]),
    async refreshData(skipConfirmation = false) {
      this.awaitingResponse = true
      const restaurantIdsBeforeSync = [
        ...this.restaurants.map(restaurant => restaurant.id),
      ]
      await this.fetchAllSquareRestaurantData()
      const restaurantsAfterSync = [...this.restaurants]
      const newRestaurants = restaurantsAfterSync.filter(
        restaurant => !restaurantIdsBeforeSync.includes(restaurant.id)
      )
      newRestaurants.forEach(restaurant => {
        this.importAllFromSquare(restaurant, skipConfirmation)
      })
      if (this.value) {
        this.importAllFromSquare(this.value, skipConfirmation)
        this.$emit("input", null)
      }

      if (!this.value && !newRestaurants.length) {
        this.$emit("needsRefresh", false)
        this.$emit("needsRefreshNew", false)
      }
      this.awaitingResponse = false
    },
    importAllFromSquare(restaurant, skipConfirmation = false) {
      const restaurantId = restaurant.id
      if (
        skipConfirmation ||
        confirm(
          "Are you sure you want to sync everything from Square into this restaurant?" +
            " This will overwrite any existing data with the data on Square."
        )
      ) {
        const restaurantChannel = this.getChannelForRestaurant(restaurantId)
        if (restaurantChannel) {
          const accessToken = restaurantChannel.accessToken
          const orgID = this.firebaseRefs.organization.id
          window.open(
            "https://us-central1-nextgenkitchens-e9608.cloudfunctions.net/dataCommitV2?token=" +
              accessToken +
              "&id=" +
              orgID,
            "_blank"
          )
          setTimeout(() => {
            this.$emit("needsRefresh", true)
          }, 500)
        } else {
          this.$emit("needsRefresh", false)
          alert(
            "This restaurant does not seem to have been properly linked to Square. Please try again."
          )
        }
      }
    },
    // TODO: Fix or remove this function as it's currently unused
    importMenuItemsFromSquare(restaurant) {
      const restaurantId = restaurant.id
      const restaurantChannel = this.getChannelForRestaurant(restaurantId)
      if (restaurantChannel) {
        const merchant = restaurantChannel.restaurantData[0].merchantId
        window.open(
          "https://us-central1-nextgenkitchens-e9608.cloudfunctions.net/syncDataFromSquare?state=1&merchant=" +
            merchant,
          "_blank"
        )
      }
    },
    getChannelForRestaurant(restaurantId) {
      if (!this.channels) {
        return
      }
      var restaurantChannel = this.channels.find(channel => {
        return channel.restaurantData
          ? channel.restaurantData[0].restaurantId === restaurantId
          : false
      })
      return restaurantChannel
    },
  },
}
</script>
